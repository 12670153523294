.selectWrap {
  border: 1px solid var(--gray-4);
  position: relative;
  z-index: 5;
  &.error {
    border-color: var(--primary-red);
  }
}
.required {
  color: var(--primary-red);
  margin-left: 3px;
}
.errorName {
  margin-top: 8px;
  z-index: 10;
  font-size: 13px;
  line-height: 20px;
  color: var(--primary-red);
  font-family: var(--font-toyota-book);
}
.disabled {
  .inputWrap {
    background-color: var(--gray-4);
  }
}
.label {
  font-size: 16px;
  color: var(--gray-7);
  position: absolute;
  transition: all 0.3s;
  top: calc(50% - 25%);
  left: 20px;
  z-index: 1;
  display: flex;
  line-height: normal;
  font-family: var(--font-toyota-book);
  &.focus {
    top: 7px;
    left: 20px;
    font-size: 10px;
  }
}
