.footer {
  border-top: 1px solid var(--gray-4);
  padding-top: 24px;
  p {
    font-family: var(--font-toyota-book);
    font-size: 13px;
    line-height: 20px;
    color: var(--gray-platinum-dark);
    margin-bottom: 16px;
  }
}
