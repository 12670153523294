.required {
  color: var(--primary-red);
  margin-left: 3px;
}
.errorName {
  position: absolute;
  bottom: -17px;
  left: 0;
  z-index: 10;
  font-size: 13px;
  color: var(--primary-red);
}
.checkBoxList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.container {
  display: block;
  position: relative;
  padding-left: 32px;
  cursor: pointer;
  font-size: 13px;
  line-height: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: calc(50% - 24px);
  padding-right: 24px;
  list-style: none;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  &:nth-child(2n) {
    width: 50%;
    padding-right: 0;
  }
  @media (max-width: 567px) {
    width: 100%;
  }
  &.error {
    .label {
      color: var(--primary-red);
    }
    .label__link {
      color: var(--primary-red);
    }
  }
  .label {
    &:hover {
      cursor: pointer;
    }
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &:hover input ~ .label {
    cursor: pointer;
  }
  input:checked ~ .checkmark {
    background-color: var(--primary-black);
    border: 1px solid var(--primary-black);
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: calc(50% - 3px);
    top: calc(50% - 5px);
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid var(--gray-6);
  border-radius: 4px;
  background: transparent;
  &:after {
    content: '';
    position: absolute;
    display: none;
  }
  &:hover {
    cursor: pointer;
  }
}
.disabled {
  .container {
    opacity: 0.4;
    cursor: default;
    input:checked ~ .checkmark {
      background-color: var(--white);
      border: 1px solid var(--primary-black);
      &:after {
        border-color: var(--primary-black);
      }
    }
  }
  &:hover input ~ .label {
    cursor: default;
  }
}
.label__link {
  text-decoration: underline;
}
