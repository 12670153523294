.tooltip {
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      cursor: pointer;
    }
  }
  &__text {
    max-width: 150px;
    text-align: center;
    font-size: 13px;
    line-height: 20px;
    padding: 13px 0;
    color: var(--bg-light-gray) !important;
    &:before {
      z-index: -1 !important;
    }
  }
}
