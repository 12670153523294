.text {
  font-size: 13px;
  line-height: 20px;
  color: var(--gray-platinum-dark);
  overflow: hidden;
  max-height: 61px;
  margin-bottom: 24px;
  @media (max-width: 991px) {
    margin-bottom: 12px;
  }
  @media (max-width: 567px) {
    margin-bottom: 0;
  }
  &.allHide {
    max-height: 0;
    margin-bottom: 0;
  }
}
.btnShowMore {
  margin-bottom: 40px;
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 15px;
  &__icon {
    margin-right: 14px;
    margin-left: 10px;
    padding-bottom: 5px;
  }
  &__text {
    font-size: 16px;
    line-height: 24px;
  }
  &:hover {
    cursor: pointer;
  }
  &.active {
    svg {
      transform: rotate(-180deg);
    }
  }
}
