.ratioComponent {
  &__item {
    margin-bottom: 19px;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      cursor: pointer;
    }
    &.active {
      .ratioComponent__item-label::before {
        background-color: var(--primary-black);
        border-color: var(--primary-black);
      }
    }
  }
  &__item-label {
    padding-left: 20px;
    font-size: 13px;
    line-height: 20px;
    color: var(--primary-black);
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: calc(50% - 4px);
      width: 5px;
      height: 5px;
      border: 1.5px solid var(--gray-7);
      border-radius: 50%;
    }
  }
}
