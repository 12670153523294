.mapWrap {
  position: relative;
  width: 100%;
  height: calc(100vh - 193px);
  min-height: 700px;
  &::before {
    content: "";
    display: block;
    padding-bottom: calc(56% - 193px);
    @media (max-width: 768px) {
      padding-bottom: calc(56% + 247px);
    }
  }
  & > div:first-child {
    z-index: 1;
    position: absolute!important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%!important;
    @media (max-width: 768px) {
      top: 247px;
      height: calc(100% - 247px)!important;
    }
  }
}
.modalMarker {
  position: absolute;
  width: 375px;
  height: 424px;
  right: -375px;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--white);
}
