.searchInput {
  width: 100%;
  position: relative;
  .icon {
    width: 17px;
    height: 19px;
    position: absolute;
    left: 0;
    top: calc(50% - 13px);
  }
  &__input {
    width: 100%;
    border: none;
    outline: none;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 13px;
    color: var(--primary-black);
    height: 57px;
    line-height: normal;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    &::placeholder {
      font-size: 13px;
      line-height: 20px;
    }
    @media (max-width: 567px) {
      height: 58px;
    }
  }
  &__result {
    display: none;
    position: absolute;
    z-index: 15;
    background-color: #fff;
    border: 1px solid #e4e4e4;
    width: 100%;
    max-height: 410px;
    overflow-y: auto;
    &.show {
      display: block;
    }
    &::-webkit-scrollbar {
      width: 2px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: var(--white);
      margin-top: 25px;
      margin-bottom: 25px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--primary-red);
    }
  }
  &__result-item {
    cursor: pointer;
    border-bottom: 1px solid #e4e4e4;
    padding: 12px 16px 12px 42px;
    display: flex;
    flex-direction: column;
    position: relative;
    span {
      &:nth-of-type(1) {
        color: var(--primary-black);
        font-size: 16px;
        line-height: 24px;
      }
      &:nth-of-type(2) {
        color: var(--gray-8);
        font-size: 13px;
        line-height: 20px;
      }
    }
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background-color: #f5f5f5;
    }
    &.active {
      background-color: #f5f5f5;
    }
  }
  &__result-item-icon {
    position: absolute;
    top: 19px;
    left: 16px;
  }
  .allResultBtn {
    padding: 12px 16px;
    font-size: 16px;
    line-height: 24px;
  }
}
.delete-icon-wrap {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  height: 18px;
  width: 18px;
  .delete-icon {
    width: 100%;
    height: 100%;
    padding-bottom: 2px;
  }
}

.dealerList {
  top: 93px;
  bottom: auto;
  left: -24px;
  position: absolute;
  z-index: 11;
  background-color: #fff;
  width: 375px;
  max-width: 375px;
  max-height: 410px;
  overflow-y: auto;
  @media (max-width: 768px) {
    top: 82px;
  }
  @media (max-width: 567px) {
    position: fixed;
    bottom: 0;
    top: auto;
    left: 0;
    width: 100vw;
    max-width: 100%;
  }
  &.show {
    display: block;
  }
  &::-webkit-scrollbar {
    width: 2px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: var(--white);
    margin-top: 25px;
    margin-bottom: 25px;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--primary-red);
  }
  .dealer {
    display: flex;
    &__number {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 1px solid var(--gray-4);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
      font-family: var(--font-toyota-semibold);
      font-size: 13px;
      &.active {
        background-color: var(--primary-black);
        color: var(--white);
      }
    }
    &__distance {
      font-size: 16px;
      line-height: 24px;
      color: var(--gray-7);
    }
    &__name {
      margin-bottom: 16px;
      font-family: var(--font-toyota-regular);
      color: var(--primary-black);
      font-size: 16px;
      line-height: 24px;
    }
  }
  &__item {
    padding: 19px 0 9px;
    margin: 0 19px;
    // margin-bottom: 19px;
    border-bottom: 1px solid #e4e4e4;
    &:last-child {
      border-bottom: none;
    }
  }
  &__item-row {
    margin-bottom: 10px;
    &.btnContainer {
      button {
        width: 100%;
      }
    }
  }
  &__contacts {
    display: block;
  }
  &__contacts-title {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
    color: var(--primary-black);
    font-family: var(--font-toyota-regular);
  }
  &__contacts-line {
    margin-bottom: 16px;
    span {
      &:nth-of-type(1) {
        margin-bottom: 5px;
        display: block;
        font-size: 13px;
        color: var(--gray-7);
      }
      &:nth-of-type(2) {
        display: block;
        font-size: 16px;
        color: var(--primary-black);
        a {
          hyphens: auto;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}
.error {
  position: absolute;
  left: 0;
  bottom: -23px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  &__text {
    font-size: 13px;
    color: #ff0022;
  }
}
