:root {
  /* fonts */
  --font-toyota-book: "Toyota-Type Book", sans-serif;
  --font-toyota-semibold: "Toyota-Type Semibold";
  --font-toyota-regular: "Toyota-Type Regular", sans-serif;

  --white: #ffffff;
  --black: #000000;
  /* 
  
   */
  --highlight-black: #15151b;
  --primary-red: #ff0022;
  --effective-blue: #0072f0;

  --gray-1: #494950;
  --gray-3: #8b8c90;
  --gray-4: #e4e4e4;
  --gray-5: #cecfd0;
  --gray-6: #eff0f0;
  --gray-7: #6c7073;
  --gray-8: #15151b;
  --gray-platinum-dark: #52565e;
  --bg-light-gray: #f5f5f5;
  --primary-black: #282830;
}
