.inputContainer {
  position: relative;
}
.inputWrap {
  border: 1px solid var(--gray-4);
  padding: 16px 20px;
  position: relative;
  display: flex;
  z-index: 0;
  height: 56px;
  width: 100%;
  &.error {
    border-color: var(--primary-red);
  }
  font-family: var(--font-toyota-book);
}
.label {
  font-size: 16px;
  color: var(--gray-7);
  position: absolute;
  transition: all 0.3s;
  top: calc(50% - 25%);
  z-index: 1;
  display: flex;
  line-height: normal;
  font-family: var(--font-toyota-book);
  &.focus {
    top: 7px;
    left: 20px;
    font-size: 10px;
  }
}
.input {
  border: none;
  outline: none;
  font-size: 16px;
  line-height: normal;
  color: var(--gray-7);
  position: absolute;
  left: 20px;
  top: calc(50% - 25%);
  z-index: 1;
  display: block;
  width: calc(100% - 80px);
  background: transparent;
  font-family: var(--font-toyota-book);
  &.focus {
    top: 38%;
  }
}
.required {
  color: var(--primary-red);
  margin-left: 3px;
}
.errorName {
  // position: absolute;
  // bottom: -15px;
  // left: 0;
  margin-top: 8px;
  z-index: 10;
  font-size: 13px;
  line-height: 20px;
  color: var(--primary-red);
  font-family: var(--font-toyota-book);
}
.disabled {
  .inputWrap {
    background-color: var(--gray-4);
  }
}
.tooltipWrap {
  position: absolute;
  right: 20px;
  top: 20px;
}
