.datePicker-wrap {
  font-size: var(--font-size-20);
  position: relative;
  border: 1px solid var(--gray-4);
  &.error {
    border: 1px solid var(--primary-red) !important;
  }
  > div {
    position: relative;
  }
  .icon {
    position: absolute;
    right: 24px;
    top: calc(50% - 10px);
    color: var(--secondary-text-color);
  }
}
.label-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label {
  font-size: 16px;
  color: var(--gray-7);
  position: absolute;
  transition: all 0.3s;
  top: calc(50% - 25%);
  left: 20px;
  z-index: 1;
  display: flex;
  line-height: normal;
  font-family: var(--font-toyota-book);
  &.focus {
    top: 7px;
    left: 20px;
    font-size: 10px;
  }
}

.datePicker {
  display: block;
  width: 100%;
  font-size: 16px;
  outline: none;
  background-color: var(--white);
  // padding: 18px;
  border: none;
  padding-right: 30px;
  position: relative;
  position: absolute;
  top: 24px;
  color: var(--gray-7);
  &.error {
    border-color: var(--primary-red);
  }
  & > div {
    display: flex;
  }
  &.error {
    border-color: var(--primary-red) !important;
  }
  &:focus {
    border-color: var(--main-color);
  }
  &:active {
    border-color: var(--primary-text-color);
  }
  &:disabled {
    background: var(--light-grey-disabled);
  }
}
.required {
  color: var(--primary-red);
  margin-left: 3px;
}
// p.errorName {
//   margin-top: 4px;
//   font-size: var(--font-size-13);
//   line-height: 14px;
//   color: var(--red);
//   width: 100%;
//   text-align: left;
//   z-index: var(--zIndex-super-main);
//   background: var(--light-red);
//   border-radius: 4px;
//   overflow: hidden;
//   padding: 8px 12px;
// }
input[type='time'] {
  border: 1px solid var(--main-color) !important;
  border-radius: 4px;
  padding: 3px 5px;
  line-height: normal;
  display: flex;
  align-items: center;
}
.errorName {
  margin-top: 8px;
  z-index: 10;
  font-size: 13px;
  line-height: 20px;
  color: var(--primary-red);
  font-family: var(--font-toyota-book);
}
