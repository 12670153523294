.additionalInfo {
  background-color: var(--bg-light-gray);
  padding: 60px 0;
  &__title {
    margin-bottom: 42px;
    text-align: center;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      margin-right: 60px;
      position: relative;
      list-style: none;
      width: calc(100% / 3 - 60px);
      padding-left: 20px;
      line-height: 24px;
      font-size: 16px;
      @media (max-width: 992px) {
        width: calc(100% / 3 - 30px);
        margin-right: 30px;
      }
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 11px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: var(--primary-red);
      }
      &:last-child {
        margin-right: 0;
      }
      @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
  }
}
.form-wrap {
  max-width: 555px;
  margin: 0 auto;
  padding-top: 40px;
  @media (max-width: 992px) {
    max-width: 100%;
  }
}
.form {
  &__line {
    margin-bottom: 32px;
  }
}
.dealer {
  max-width: 704px;
  margin: 0 auto;
  position: relative;
  min-height: 160px;
  padding-bottom: 40px;
  @media (max-width: 767px) {
    max-width: 555px;
  }
  @media (max-width: 567px) {
    max-width: 90%;
  }
  &__title {
    font-size: 22px;
    line-height: 30px;
    padding-top: 40px;
    padding-bottom: 32px;
  }
  &__list {
    margin-bottom: 32px;
  }
  &__row {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--gray-4);
    padding: 17px 0;
    &:last-child {
      border-bottom: 1px solid var(--gray-4);
    }
    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 8px 0;
    }
  }
  &__row-td {
    display: flex;
    flex-direction: column;
    &.td-fildName {
      font-size: 12px;
      line-height: 20px;
      color: var(--primary-black);
      margin-right: 12px;
      &:first-letter {
        text-transform: uppercase;
      }
    }
    &.td-value {
      font-size: 16px;
      line-height: 24px;
      color: var(--primary-black);
      p {
        margin-bottom: 4px;
      }
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
}
.map {
  height: calc(100% - 193px);
}
