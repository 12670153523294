.text {
  font-size: 13px;
  line-height: 20px;
  color: var(--gray-platinum-dark);
  overflow: hidden;
  max-height: 0;
  &.allHide {
    max-height: 0;
  }
}
.btnShowMore {
  position: relative;
  display: flex;
  align-items: center;
  &__icon {
    margin-right: 14px;
    margin-left: 10px;
    padding-bottom: 6px;
  }
  &__text {
    font-size: 16px;
    line-height: 24px;
  }
  &:hover {
    cursor: pointer;
  }
  &.active {
    svg {
      transform: rotate(-180deg);
    }
  }
}
