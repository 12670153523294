.markerWrap {
  top: -40px !important;
  z-index: 1;
  &.active {
    z-index: 100 !important;
  }
}
.marker {
  position: relative;
  border: none;
  background: transparent;
  height: 55px;
  width: 32px;
  z-index: 10;
  &:hover {
    cursor: pointer !important;
  }
  &__text {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 13px;
  }
  &__icon {
    height: 55px;
    width: 32px;
    position: absolute;
    left: 0;
    top: 0;
  }
  &.active {
    .marker__text {
      color: var(--white);
    }
  }
}
.modalMarker {
  display: none;
  position: absolute;
  width: 375px;
  right: -385px;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--white);
  &__close-btn {
    width: 17px;
    height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: var(--primary-black);
    color: white;
    font-size: 12px;
    border-radius: 50%;
    opacity: 0.9;
    cursor: pointer;
    svg {
      width: 5px;
      height: 5px;
    }

    &:active {
      opacity: 0.8;
    }
  }
  &.show {
    display: block;
  }
  &__item {
    padding: 19px 0 9px;
    margin: 0 19px;
    // margin-bottom: 19px;
    border-bottom: 1px solid #e4e4e4;
    &:last-child {
      border-bottom: none;
    }
  }
  &__item-row {
    margin-bottom: 10px;
    &.btnContainer {
      button {
        width: 100%;
      }
    }
  }
  &__contacts {
    display: block;
  }
  &__contacts-title {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
    color: var(--primary-black);
    font-family: var(--font-toyota-regular);
  }
  &__contacts-line {
    margin-bottom: 16px;
    span {
      &:nth-of-type(1) {
        margin-bottom: 5px;
        display: block;
        font-size: 13px;
        line-height: 20px;
        color: var(--gray-7);
      }
      &:nth-of-type(2) {
        display: block;
        font-size: 16px;
        color: var(--primary-black);
        a {
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}
.dealer {
  display: flex;
  &__number {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid var(--gray-4);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    font-family: var(--font-toyota-semibold);
    font-size: 13px;
    &.active {
      background-color: var(--primary-black);
      color: var(--white);
    }
  }
  &__distance {
    font-size: 16px;
    line-height: 24px;
    color: var(--gray-7);
  }
  &__name {
    margin-bottom: 16px;
    font-family: var(--font-toyota-regular);
    color: var(--primary-black);
    font-size: 16px;
    line-height: 24px;
  }
}
