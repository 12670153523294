.chatBot {
  margin-top: 40px;
}
.tabs {
  &__title {
    font-size: 16px;
    line-height: 24px;
    font-family: var(--font-toyota-regular);
    margin-bottom: 15px;
  }
  &__label-text {
    font-size: 16px;
    line-height: 24px;
    text-transform: none;
    font-family: var(--font-toyota-regular);
  }
}
.tabPanel {
  &__qr-code {
    border: 1px solid #e4e4e4;
    padding: 10px;
    width: 130px;
    height: 130px;
    margin: 0 auto;
    img {
      width: 100%;
    }
    margin-bottom: 16px;
  }
  &__qr-code-text {
    text-align: center;
    font-size: 13px;
    line-height: 20px;
    color: var(--gray-7);
    max-width: 299px;
  }
}
.tabPanelList {
  margin-top: 40px;
  @media (max-width: 991px) {
    display: none;
  }
}

.socialTabs {
  flex-direction: column;
  align-items: center;
}
