.header {
  // box-shadow: 0px 1px 1px rgba(177, 177, 177, 0.25);
  padding-top: 48px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(177, 177, 177, 0.25);
}
.steps {
  display: flex;
  justify-content: center;
  padding-top: 48px;
  &__list {
    display: flex;
  }
  &__list-item {
    margin-right: 42px;
    display: flex;
    align-items: center;
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
    @media (max-width: 767px) {
      margin-right: 24px;
    }
    &:last-child {
      margin-right: 0;
    }
    .number {
      font-size: 16px;
      line-height: 24px;
      color: var(--gray-3);
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: var(--gray-6);
      display: flex;
      justify-content: center;
      margin-right: 8px;
      border: 2px solid var(--gray-6);
      display: flex;
      align-items: center;
      justify-content: center;
      // padding-bottom: 4px;
      border-radius: 50%;
      @media (max-width: 767px) {
        font-size: 0;
        width: 14px;
        height: 14px;
        border-width: 3px;
        background-color: var(--gray-5);
        border-color: var(--white);
        margin-right: 0;
      }
      &.successStep {
        background-color: var(--primary-black) !important;
        padding-bottom: 0;
        border: 2px solid var(--primary-black);
        // &:hover {
        //   cursor: pointer;
        //   opacity: 0.8;
        // }
      }
    }
    .label {
      font-size: 13px;
      line-height: 20px;
      color: var(--gray-1);
      font-family: var(--font-toyota-book);
      @media (max-width: 767px) {
        display: none;
      }
    }
    &.active {
      .number {
        background-color: var(--white);
        color: var(--primary-black);
        border-color: var(--primary-black);
      }
      .label {
        text-transform: uppercase;
        font-family: var(--font-toyota-semibold);
      }
    }
  }
}
