* {
  font-family: var(--font-toyota-book);
  color: var(--primary-black);
  box-sizing: border-box;
}
h1 {
  color: #191a1e;
  font-size: 34px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: var(--font-toyota-semibold);
  line-height: 40px;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 28px;
    line-height: 33px;
  }
}
h2 {
  color: #191a1e;
  font-size: 22px;
  font-weight: 400;
  font-family: var(--font-toyota-regular);
  line-height: 30px;
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 27px;
  }
}
h3 {
  font-size: 13px;
  line-height: 20px;
  font-family: var(--font-toyota-semibold);
  color: var(--primary-black);
  margin-bottom: 25px;
  text-transform: uppercase;
}
.row {
  margin-left: -32px;
  margin-right: -32px;
}
.container {
  max-width: 1280px;
  margin: 0 auto;
  padding-left: 32px;
  padding-right: 32px;
  @media (max-width: 767px) {
    padding-right: 16px;
    padding-left: 16px;
  }
}
