.secondStep {
  padding-bottom: 80px;
}

.vinInfo {
  max-width: 555px;
  margin: 0 auto;
  &__header {
    padding: 40px 0;
    display: flex;
  }
  &__content {
    margin-bottom: 40px;
  }
  &__footer {
    margin-bottom: 80px;
    @media (max-width: 767px) {
      margin-bottom: 40px;
    }
  }
  &__footer-text {
    font-size: 13px;
    line-height: 20px;
    text-transform: uppercase;
    font-family: var(--font-toyota-semibold);
    margin-bottom: 24px;
    color: #52565e;
    font-weight: 600;
  }
  &__footer-dynamicBlock {
    font-size: 13px;
    line-height: 20px;
    font-family: var(--font-toyota-semibold);
    margin-bottom: 24px;
    color: #52565e;
    font-weight: 600;

    h1 {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 48px;
    }
    h2 {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 16px;
    }
    h3 {
      font-size: 12px;
      line-height: 19px;
      margin-bottom: 16px;
    }
    p {
      margin-bottom: 48px;
      color: #52565e;
    }
    a {
      cursor: pointer;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    ul {
      padding-left: 44px;
      margin-bottom: 48px;
      li {
        font-size: 16px;
        line-height: 24px;
        position: relative;
        margin-bottom: 8px;
        color: #52565e;
        &::before {
          content: '';
          display: block;
          position: absolute;
          left: -24px;
          top: 11px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: var(--primary-red);
        }
      }
    }
    ol {
      margin-bottom: 48px;
      counter-reset: item;
      li {
        font-size: 16px;
        line-height: 24px;
        position: relative;
        margin-bottom: 8px;
        list-style-type: 1;
        counter-increment: step-counter;
        color: #52565e;
        &::before {
          content: counter(step-counter) '. ';
          color: var(--primary-red);
          font-weight: 700;
        }
      }
    }
    b {
      font-weight: 700;
    }
    em {
      font-style: italic;
    }
  }
}
.vin {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  &__info {
    font-size: 22px;
    line-height: 30px;
    color: var(--black);
    p {
      line-height: 27px;
      @media (max-width: 567px) {
        &:nth-of-type(1) {
          margin-right: 5px;
        }
      }
    }
    @media (max-width: 567px) {
      margin-bottom: 24px;
      font-size: 19px;
      display: flex;
    }
  }
  &__btn {
    padding: 12px 24px;
    font-size: 16px;
    line-height: 24px;
    border: 1px solid var(--primary-black);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background: var(--primary-black);
      color: var(--white);
    }
  }
}
.info-list {
  margin-bottom: 32px;
  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--gray-4);
    padding: 17px 0;
    &:last-child {
      border-bottom: 1px solid var(--gray-4);
    }
    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 8px 0;
    }
  }
  &__row-td {
    display: flex;
    align-items: center;
    .td-fildName {
      font-size: 16px;
      line-height: 24px;
      color: var(--primary-black);
      margin-right: 12px;
      &:first-letter {
        text-transform: uppercase;
      }
    }
    .td-value {
      font-size: 13px;
      line-height: 20px;
      color: var(--gray-7);
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
}
.warrantyResult {
  display: flex;
  border-bottom: 1px solid var(--gray-4);
  &__icon {
    position: relative;
    width: 32px;
    height: 32px;
    // background-color: var(--primary-black);
    border-radius: 50%;
    margin-right: 18px;
    &.valid {
      background-color: #4CAF50;
      &:after {
        position: absolute;
        content: '';
        border: solid #fff;
        border-width: 0 1.2px 1.2px 0;
        height: 12px;
        left: calc(50% - 3px);
        top: calc(50% - 7px);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        width: 6px;
      }
    }
    &.invalid {
      background-color: #D40605;
      &:after {
        position: absolute;
        content: '';
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        height: 1px;
        width: 12px;
        background-color: #fff;
      }
      &:before {
        position: absolute;
        content: '';
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        height: 1px;
        width: 12px;
        background-color: #fff;
      }
    }
  }
  &__text {
    width: calc(100% - 50px);
    p {
      font-family: var(--font-toyota-semibold);
      font-size: 13px;
      color: var(--primary-black);
      line-height: 20px;
      margin-bottom: 16px;
      &:nth-of-type(1) {
        text-transform: uppercase;
      }
      &:last-child {
        margin-bottom: 17px;
      }
      &.warrantyResult__text-descr {
        font-family: var(--font-toyota-book);
        text-align: justify;
        text-transform: none;
        ul {
          display: flex;
          flex-wrap: wrap;
          li {
            margin-right: 16px;
            position: relative;
            list-style: none;
            width: calc(100% / 2 - 16px);
            padding-left: 20px;
            line-height: 24px;
            font-size: 13px;
            margin-bottom: 16px;
            @media (max-width: 992px) {
              width: calc(100% / 2 - 16px);
              margin-right: 16px;
            }
            &::before {
              content: '';
              display: block;
              position: absolute;
              left: 0;
              top: 11px;
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background-color: var(--primary-red);
            }
            &:last-child {
              margin-right: 0;
            }
            @media (max-width: 767px) {
              width: 100%;
              margin-right: 0;
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  }
}
