.modal {
  padding: 42px 60px;
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
  @media (max-width: 991px) {
    min-width: 90%;
    padding: 16px 16px;
  }
  &__content {
    // height: 100%;
  }
  &__close-btn {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 24px;
    top: 24px;
    background-color: var(--primary-black);
    color: white;
    font-size: 12px;
    border-radius: 50%;
    opacity: 0.9;
    cursor: pointer;
    svg {
      width: 10px;
      height: 10px;
    }
    @media (max-width: 567px) {
      right: 17px;
      top: 17px;
    }

    &:active {
      opacity: 0.8;
    }
  }
  /* width */
  &::-webkit-scrollbar {
    width: 2px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: var(--white);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--primary-red);
  }

  /* width */
  &::-moz-scrollbar {
    width: 2px;
  }

  /* Track */
  &::-moz-scrollbar-track {
    background: var(--white);
  }

  /* Handle */
  &::-moz-scrollbar-thumb {
    background: var(--primary-red);
  }
}
.privacyContent {
  padding: 80px 40px 40px;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  // width: 80%;
  // height: 80%;
  position: absolute;
  // left: calc(50% - 40%);
  // top: calc(50% - 40%);
  background-color: var(--white);
  overflow-y: auto;
  h1 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 48px;
  }
  h2 {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;
  }
  h3 {
    font-size: 12px;
    line-height: 19px;
    margin-bottom: 16px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 48px;
  }
  ul {
    padding-left: 44px;
    margin-bottom: 48px;
    li {
      font-size: 16px;
      line-height: 24px;
      position: relative;
      margin-bottom: 8px;
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: -24px;
        top: 11px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: var(--primary-red);
      }
    }
  }
  ol {
    margin-bottom: 48px;
    counter-reset: item;
    li {
      font-size: 16px;
      line-height: 24px;
      position: relative;
      margin-bottom: 8px;
      list-style-type: 1;
      counter-increment: step-counter;
      &::before {
        content: counter(step-counter) '. ';
        color: var(--primary-red);
        font-weight: 700;
      }
    }
  }

  /* width */
  &::-webkit-scrollbar {
    width: 2px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: var(--white);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--primary-red);
  }

  /* width */
  &::-moz-scrollbar {
    width: 2px;
  }

  /* Track */
  &::-moz-scrollbar-track {
    background: var(--white);
  }

  /* Handle */
  &::-moz-scrollbar-thumb {
    background: var(--primary-red);
  }
  &__text {
  }
}
