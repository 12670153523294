.additionalInfo {
  background-color: var(--bg-light-gray);
  padding: 60px 0;
  @media (max-width: 767px) {
    padding: 40px 0;
  }
  &__title {
    margin-bottom: 42px;
    text-align: center;
    @media (max-width: 767px) {
      margin-bottom: 24px;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      margin-right: 60px;
      position: relative;
      list-style: none;
      width: calc(100% / 3 - 60px);
      padding-left: 20px;
      line-height: 24px;
      font-size: 16px;
      @media (max-width: 992px) {
        width: calc(100% / 3 - 30px);
        margin-right: 30px;
      }
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 11px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: var(--primary-red);
      }
      &:last-child {
        margin-right: 0;
      }
      @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
  }
}
.form-wrap {
  max-width: 555px;
  margin: 0 auto;
  padding-top: 40px;
  @media (max-width: 992px) {
    max-width: 100%;
  }
}
.form {
  &__line {
    margin-bottom: 32px;
  }
}
.footer {
  margin-bottom: 86px;
  font-size: 13px;
  line-height: 20px;
  color: var(--gray-platinum-dark);
  a {
    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
  }
  p,
  span,
  div {
    color: var(--gray-platinum-dark);
  }
  @media (max-width: 767px) {
    margin-bottom: 40px;
  }
}
